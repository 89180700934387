import React from 'react';
// import sections
import  API from '../Api';
import Header from '../components/layout/Header';
import HeaderInfluencer from '../components/layout/HeaderInfluencer';
import HeaderLoggedIn from '../components/layout/HeaderLoggedIn';
import HeaderOnboarding from '../components/layout/HeaderOnboarding';
import HeadlineFullScreen from '../components/sections/HeadlineFullScreen';
import InfluencerDisplay from '../components/elements/InfluencerDisplay';
import PredictionDetailView from '../components/elements/PredictionDetailView';
import YearBadgeWidget from '../components/elements/YearBadgeWidget';
import InfluencerPredictionDisplay from '../components/InfluencerPredictionDisplay';
import ReactGA from "react-ga4";
import { faShareNodes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RWebShare } from "react-web-share";
import Button from '@mui/material/Button';
import queryString from "query-string"
import {View, Image, StyleSheet} from 'react-native';
import Box from '@mui/material/Box';

import { ThemeProvider, createTheme } from '@mui/material/styles';
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});



class Feed extends React.Component {


  constructor () {


    super();

    this.state = {
        influencerKey: undefined,
        newsId: undefined,
        predictionId : undefined,
        viewType: "unknown",
        isLoading: true,
        influencerData: null,
        newsData: null,
        predictionData: null,
        news: [],
        predictions: [],
        pagedFeed: true,
        feedLabel: "Predictions Made"
    };
    ReactGA.initialize("G-WQP2CKSW84");
    //ReactGA.send({ hitType: "pageview", page: "/feed_page", title: "feed_page" });


    }
    updateNews = (newsUpdate) =>{
        this.setState({ news: this.state.news.concat(newsUpdate) });
    }
    updatePredictions = (predictionsUpdate) =>{
        this.setState({ predictions: this.state.predictions.concat(predictionsUpdate) });
    }

    swapFeed = () => {
        console.log("was " + this.state.pagedFeed);
        this.setState({pagedFeed : !this.state.pagedFeed});
        console.log("now " + this.state.pagedFeed);
        this.feedLabel(this.state.pagedFeed)
    }

    feedLabel = (feed) => {
        if (!feed){
            console.log("will offer predictions");
            this.setState({feedLabel : "predictions made"});
        }else{
            console.log("will offer ideas");
            this.setState({feedLabel : "ideas feed"});
        }
    }

    componentDidUpdate() {
        if (this.state.influencerData !== null){
        //console.log("influencer ready");
        if (this.state.viewType === "news" && this.state.newsData != null){
            //console.log(this.state.newsData);
            //console.log("news ready");
            if (this.state.isLoading == true){
            this.setState({ isLoading: false });
            }else{
            //console.log("loading is already " + this.state.isLoading);
            }
        }
        if (this.state.viewType === "prediction" && this.state.predictionData != null){
            //console.log("prediction ready");
            if (this.state.isLoading == true){
            this.setState({ isLoading: false });
            }else{
            //console.log("loading is already " + this.state.isLoading);
            }
        }
        if (this.state.viewType === "influencer" /*&& this.state.predictionData != null && this.state.newsData != null */){
            //console.log("influencer ready");
            if (this.state.isLoading == true){
            this.setState({ isLoading: false });
            }else{
            //console.log("loading is already " + this.state.isLoading);
            }
        }

        }
        //console.log("loading is " + this.state.isLoading);
    }
    componentDidMount(){


    // this will either be a general influencer page, news page or prediction page, depending on the queries passed
    const queryParams = queryString.parse(window.location.search)
    const influencerKey = queryParams['influencer'];
    const newsId = queryParams['news'];
    const predictionId = queryParams['prediction'];
    var LocalviewType = null;
    if (newsId === undefined && predictionId === undefined && influencerKey !== undefined){
        this.setState({ viewType: "influencer" });
        LocalviewType = "influencer";
        console.log("setting up influencer");
    }
    else if (newsId === undefined && predictionId !== undefined){
        this.setState({ viewType: "prediction" });
        LocalviewType = "prediction";
        console.log("setting up prediction");
    }
    else if (newsId !== undefined && predictionId === undefined){
        this.setState({ viewType: "news" });
        LocalviewType = "news";
        console.log("setting up news");
    }
    //this.setState({ viewType: LocalviewType }, this.myFunction);
    console.log(influencerKey);
    console.log(newsId);
    console.log(predictionId);
    if (influencerKey !== undefined)
    {
    console.log("asking for influencer");
        API({
        url: '/influencer/'+influencerKey
        })
        .then(response => {
        if (response.statusText === "OK"){
        this.setState({ influencerData : response.data });
        //this.state.influencerData = response.data;

        }
        }).catch(response =>{
        });
    }
    console.log("state view " +this.state.viewType);
    console.log("new view " +LocalviewType);
    if (LocalviewType === "prediction")
    {
    console.log("asking for prediction id");
        API({
        url: '/predictions/id/'+predictionId
        })
        .then(response => {
        if (response.statusText === "OK"){
        this.setState({ predictionData : response.data });
            if (influencerKey == undefined){
                console.log("asking for influencer from news id");
                API({
                url: '/influencer/'+response.data[0]['influencerKey']
                })
                .then(response => {
                if (response.statusText === "OK"){
                //this.setState({ influencerData : response.data });
                this.setState({ influencerData : response.data });
                }
                }).catch(response =>{
                });
            }
        }
        }).catch(response =>{
        });
    }
    //if (LocalviewType === "influencer")
    //{
    // console.log("asking for all predictions");
    //     API({
    //     url: '/predictions/influencer/'+influencerKey
    //     })
    //     .then(response => {
    //     if (response.statusText === "OK"){
    //     this.setState({ predictionData : response.data });

    //     }
    //     }).catch(response =>{
    //     });
    //}

    if (LocalviewType === "news")
    {
        console.log("asking for news id");
        API({
        url: '/news/id/'+newsId
        })
        .then(response => {
        if (response.statusText === "OK"){
            this.setState({ newsData : response.data });
            if (influencerKey == undefined){
                console.log("asking for influencer from news id");
                API({
                url: '/influencer/'+response.data[0]['influencerKey']
                })
                .then(response => {
                if (response.statusText === "OK"){
                //this.setState({ influencerData : response.data });
                this.setState({ influencerData : response.data });
                }
                }).catch(response =>{
                });
            }
        }
        }).catch(response =>{
        });
    }
    //if (LocalviewType === "influencer")
    //{
    // console.log("asking for all news");
    //     API({
    //     url: '/news/influencer/'+influencerKey
    //     })
    //     .then(response => {
    //     if (response.statusText === "OK"){
    //     this.setState({ newsData : response.data });
    //     }
    //     }).catch(response =>{
    //     });
    //}

    }

render () {
    const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
  },
});

  if (this.state.isLoading) {
            return <div className="App">Loading...</div>;
  }else{

// if ( this.state.viewType !== "prediction"){
//     let n = 0;
//     var news = "<h2>News</h2>";
//     while (n < this.state.newsData.length) {
//     if (n == 5)
//     break;
//         news += "<p>"+this.state.newsData[n].title+"</p>";
//         n++;
//     }
//     console.log("news:" + news);
// }
// if ( this.state.viewType !== "news"){
//     let m = 0;
//     var predictions = "<h2>Predictions</h2>";
//     while (m < this.state.predictionData.length) {
//         predictions += "<p>"+this.state.predictionData[m].prediction+"</p>";
//         m++;
//     }
//     console.log("Predictions:" + predictions);
// }
const pic = StyleSheet.create({
    profile: {
      width: 300,
      height: 300,
      borderRadius: 30,
    },
  });

  return (
    <>
    <meta name="keywords" content="news feed, ideas, influencers, posts, compare, predictions, benchmark" />
    <meta name="description" content="News feed" />
<ThemeProvider theme={darkTheme}>
{ this.props.loggedIn && this.props.onboarded && this.props.influencer && <HeaderInfluencer navPosition="right" className="reveal-from-bottom" /> }
{ this.props.loggedIn && this.props.onboarded && !this.props.influencer && <HeaderLoggedIn navPosition="right" className="reveal-from-bottom" /> }
{ !this.props.loggedIn && !this.props.onboarded && <Header navPosition="right" className="reveal-from-bottom" /> }
{ this.props.loggedIn && !this.props.onboarded && <HeaderOnboarding navPosition="right" className="reveal-from-bottom" /> }
<div style={{ paddingTop: '100px', paddingLeft: '15px',paddingRight: '15px'}}>
<>

<Box
    sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        p: 1,
        m: 1,
        bgcolor: 'background.paper',
        flexDirection: 'row',
        flexWrap: 'wrap',
        flexShrink: 1,
        flexGrow: 4,
        borderRadius: 1,
    }}>
    <Box>
            <Image
                style={pic.profile}
                source={
                    'https://illbeatwallstreet.com/profiles/'+ this.state.influencerData.profilePicture
                }
              />
    </Box>
    <Box>
        <Box
            sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            p: 1,
            m: 1,
            bgcolor: 'background.paper',
            flexDirection: 'column',
            flexGrow: 4,
            borderRadius: 1,
            }}>
            <Box>
                <h1><a href={"feed?influencer="+this.state.influencerData.influencerKey}>{this.state.influencerData.displayName}</a>
<>{ this.state.viewType == "influencer" && <RWebShare data={{ text: this.state.influencerData.displayName + " at Ill Beat Wall Street", url: 'https://illbeatwallstreet.com/feed?influencer='+this.state.influencerData.influencerKey, title: this.state.influencerData.displayName + " at Ill Beat Wall Street", }}><Button><FontAwesomeIcon icon={faShareNodes} size="lg" /></Button></RWebShare>}</>
                </h1>
            </Box>
            <Box>
                <p style={{whiteSpace: 'pre-line'}}>{this.state.influencerData.description}</p>
            </Box>
            <Box
            sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            p: 1,
            m: 1,
            bgcolor: 'background.paper',
            flexDirection: 'row',
            flexWrap: 'wrap',
            borderRadius: 1,
            }}>
                <Box>
                    <YearBadgeWidget year={"Per Year"} netPercentage={this.state.influencerData.perYear.netPercentage} percentile={this.state.influencerData.perYear.percentile} />
                </Box>
                {this.state.influencerData.years.map((data) => (<Box><YearBadgeWidget year={data['year']} netPercentage={data['netPercentage']} percentile={data['percentile']} /></Box>))}
            </Box>
            {this.state.viewType == "influencer" &&
            <Box> 
        <Button onClick={this.swapFeed}>
        { this.state.feedLabel}
        </Button>
            </Box>
        }
        </Box>

    </Box>
</Box>

{ this.state.viewType == "influencer" && !this.state.pagedFeed && <InfluencerPredictionDisplay influencer={this.state.influencerData.influencerKey}/> }
{ this.state.viewType == "influencer" && (!this.state.isLoading) && <InfluencerDisplay influencer={this.state.influencerData.influencerKey} news={this.state.news} predictions={this.state.predictions} updateNews={this.updateNews} updatePredictions={this.updatePredictions}/>}
{ this.state.viewType == "news" && <HeadlineFullScreen userTags={this.props.tags} date={this.state.newsData[0]['date']} headline={this.state.newsData[0]["title"]}  comment={this.state.newsData[0]["comment"]} link={this.state.newsData[0]["link"]} tags={this.state.newsData[0]["tags"]} isNews={true} single={true}/> }
{ this.state.viewType == "prediction" && <PredictionDetailView displayName={this.state.influencerData.displayName} userTags={this.props.tags} prediction={this.state.predictionData} />}

</>
  </div>
  </ThemeProvider>
  </>
  );
  }
  }
}

export default Feed;



